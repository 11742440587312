import { AuthConfig } from "../type";
import { Amplify } from "aws-amplify";

export default class AmplifyConfiguration {
  /**
   * Initialize & configure Amplify Auth configurations.
   * @param config Argo Auth config object.
   */
  public static initialize(config : AuthConfig) : void {
    Amplify.configure({
      Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.clientId,
        authenticationFlowType: 'CUSTOM_AUTH',
        // belows are required for federated SSO
        oauth: {
          domain: config.domain,
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: config.redirectSignIn,
          redirectSignOut: config.redirectSignOut,
          responseType: 'code',
          identityProvider: 'Amazon'
        }
      }
    });
  }
}