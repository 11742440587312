import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Spinner } from '@amzn/awsui-components-react-v3';
import OIDC from '@awsscm/awsscm-auth-manager/auth';
import { Auth } from '@awsscm/awsscm-auth-manager/auth/AuthManager';
import { AuthSession, AuthUser } from '@awsscm/awsscm-auth-manager/auth/type';
import { Level } from '@katal/logger';
import KatalMetricsPublisher from '@katal/metrics/lib/KatalMetricsPublisher';
import { ArgoAppContextProvider } from '../../../ArgoContext';
import { credentialStorage } from '../../../utils/CredentialStorage';
import katalLogger from '../../../utils/logger/logger';
import initialMetricsPublisher, { ArgoMetricsConfig } from '../../../utils/metrics/metrics';
import argoHadesClient from '../../../utils/ArgoHades/index';
import { LegalAgreementCaptivePortal } from '../../legalAgreement';
import { fetchWithWebIdentity, fetchWithAWSAuth } from '../../../utils/helperMethods/FederatedIdentity';
import { sendInternalExternalMetric } from './internalExternalMetric';
import { CognitoAppProps } from './CognitoApp.types';
import { AuthConfig } from '../../../ArgoApp.types';
import { GetAcceptanceForUserOut, GetDocumentContentOut } from '../../../utils/ArgoHades/interfaces';
import { queueApplicationStartMetric } from './queueApplicationStartMetric';
import { useUserUsageMetrics } from '../../../utils/metrics/userUsageMetrics/useUserUsageMetrics';
import { BROWSER_SESSION_ID_KEY } from '../../../utils/metrics/userUsageMetrics/constants';
import { shouldRefreshArgoSession } from '../../../utils/ArgoRefresh/utils';

const SESSION_REFRESH_PERIOD = 10000;

let authSession: AuthSession | null = null;

function sendArgoHadesMetric(metricsPublisher: KatalMetricsPublisher, apiName: string, latency: number) {
  metricsPublisher.newChildActionPublisherForMethod('ArgoHadesLatency').publishTimerMonitor(apiName, latency);
}

function sendRefreshSessionMetric(metricsPublisher: KatalMetricsPublisher, metricName: string, value: number) {
  metricsPublisher.newChildActionPublisherForMethod('ArgoSessionRefresh').publishCounter(metricName, value);
}

export const CognitoApp = (props: CognitoAppProps) => {
  const [authInitialized, setAuthInitialized] = React.useState<boolean>(false);
  const [legalAgreementAccepted, setLegalAgreementAccepted] = React.useState<boolean>(props.region !== 'us-west-2');
  const [siteTermsDocument, setSiteTermsDocument] = React.useState<string>('');

  let logger = katalLogger(
    Level.INFO,
    {
      appName: props.appName
    },
    false,
    props.katalLoggerUrl
  );

  const cognitoAppMetricsConfig: ArgoMetricsConfig = {
    appName: props.appName,
    region: props.cognito.region,
    frameworkStage: props.frameworkStage,
    cell: props.cell,
    browserName: props.browserName,
    deviceType: props.deviceType,
    deviceOS: props.deviceOS,
    locale: props.locale,
    applicationVisitId: props.applicationVisitId,
    argoSessionId: props.argoSessionId,
    loggerUrl: props.katalLoggerUrl
  };

  const metricPublisher = initialMetricsPublisher(cognitoAppMetricsConfig);

  // On initial render, fire application Warm/Cold start metric
  React.useEffect(() => {
    queueApplicationStartMetric(metricPublisher);
  }, []);

  // When the props.cognito object assignment is modified (functionally, this only happens on initial render),
  // setup OIDC and trigger the sign in flow.
  React.useEffect(() => {
    let cancelled = false;
    const cognito = props.cognito as AuthConfig;

    OIDC.configure({
      region: cognito.region,
      clientId: cognito.am_clientId,
      authorityUrl: cognito.am_identityProviderUrl,
      identityProviderUrl: cognito.am_identityProviderUrl,
      domain: cognito.domain,
      scope: cognito.am_scope,
      redirectSignIn: cognito.am_redirectSignIn,
      redirectSignOut: cognito.am_redirectSignOut,
      responseType: cognito.am_responseType,
      jwkEndpoint: cognito.am_jwkEndpoint,
      authorizationEndpoint: cognito.am_authorizationEndpoint,
      endSessionEndpoint: cognito.am_endSessionEndpoint,
      sessionServiceEndpoint: cognito.am_sessionServiceEndpoint,
      tokenExpirationTime: 900,
      implicitFlowRefreshEndpoint: cognito.am_implicitFlowRefreshEndpoint,
      tokenEndpoint: cognito.am_tokenEndpoint,
      useSelfHostedLoginUI: cognito.am_useSelfHostedLoginUI,
      userPoolId: cognito.userPoolId
    });

    function onResolvedSession(session: AuthSession | null) {
      if (!cancelled) {
        authSession = session;
        if (!authInitialized) {
          setAuthInitialized(true);
        }
      }
    }

    Auth.signinSession()
      .then(onResolvedSession)
      .catch(() => {
        // If there's an error with sign-in not due to application unmount, retry sign-in after 500ms.
        // TODO: evaluate if this is actually worthwhile. We don't yet have metrics to know how common this usecase is,
        // but the static wait here seems like a code smell.
        if (!cancelled) {
          setTimeout(() => {
            Auth.signinSession()
              .then(onResolvedSession)
              .catch((error) => {
                logger.error('Unable to sign in', error);
              });
            // 500ms matches what the Argo portal currently does and "works" in testing.
            // https://tiny.amazon.com/160ql49xw/codeamazpackAWSSblob5362src
          }, 500);
        }
      });

    return () => {
      cancelled = true;
    };
  }, [props.cognito]);

  function onAcceptLegalAgreement(userName: string) {
    setLegalAgreementAccepted(true);
    argoHadesClient.setAcceptanceForUser(userName);
  }

  // After a user has been signed in, check to see if they have agreed to the Terms of Use.
  React.useEffect(() => {
    if (!legalAgreementAccepted) {
      if (credentialStorage.getJwtToken() !== '') {
        const getAcceptanceStartTime = performance.now();
        argoHadesClient.getAcceptanceForUser(credentialStorage.getUserName())
          .then((getAcceptanceResult: GetAcceptanceForUserOut) => {
            const getAcceptanceDuration = performance.now() - getAcceptanceStartTime;
            sendArgoHadesMetric(metricPublisher, "GetAcceptanceForUserLatency", getAcceptanceDuration);

            if (getAcceptanceResult.hasAccepted) {
              setLegalAgreementAccepted(true);
            } else {
              const getSiteTermsStartTime = performance.now();
              argoHadesClient.getSiteTerms().then((getDocumentResult: GetDocumentContentOut) => {
                const getSiteTermsDuration = performance.now() - getSiteTermsStartTime;
                sendArgoHadesMetric(metricPublisher, "GetSiteTermsLatency", getSiteTermsDuration);

                setSiteTermsDocument(getDocumentResult.content);
              });
            }
          })
          .catch((error) => {
            logger.error('Error in getAcceptanceForUser request', error);
            argoHadesClient.getSiteTerms().then((getDocumentResult: GetDocumentContentOut) => {
              setSiteTermsDocument(getDocumentResult.content);
            });
          });
      }
    }
  }, [authInitialized]);

  // After a user has signed in, initialize user usage tracking metrics
  useUserUsageMetrics(metricPublisher, authInitialized, props.appName);

  function refreshArgoUserSession() {
    Auth.refreshSession()
    .then((session) => {
      authSession = session;
      const refreshedJwtToken = session!.idToken;
      const refreshedSessionCredentials = session!.credentials;
      if(refreshedJwtToken && refreshedSessionCredentials && refreshedSessionCredentials.credentials) {
        credentialStorage.setUserAttributes(refreshedJwtToken, refreshedSessionCredentials.userDirectoryGroupList);
        credentialStorage.setUserCredentials(refreshedSessionCredentials.credentials);
        sendRefreshSessionMetric(metricPublisher, "argoRefreshSuccess", 1);
      } else {
        // It will enter here only when session service api fails to return with response (rare).
        // Note: IdToken will be present because Auth.refreshSession() silently signs in, and replaces new OIDC tokens in local storage.
        credentialStorage.setUserAttributes(refreshedJwtToken);
        sendRefreshSessionMetric(metricPublisher, "sessionCredentialsRefreshFailure", 1);
      }
    })
    .catch((error) => {
      logger.error('Unable to refresh session', error);
      sendRefreshSessionMetric(metricPublisher, "argoRefreshFailure", 1);
    });
  }

  /*
    Because this is inline in the rendering function, we have this if statement
    to ensure that the setInterval is only set up once.

    If this does not exist then it would be created twice, once on the initial render and once on
    the rerender when authInitialized is set.

    Note: [(Field, Expiration Time in hour)]
     1. Expiration Time - [(SSC, 1), (IdToken, 1), (RefreshToken, 24), (AccessToken, 1)]
     2. Below logic is set to run every 10 seconds,
        (a) checks if IdToken, and Session Service Credentials are about to be expired in 2 minutes or already expired.
          (i) if yes, refresh using the Auth.refreshSession() which automatically sets the new IdToken or Session Credentials inside local storage.
          (ii) if no, do nothing, wait until the next expiry check in 10 second interval.
   */
  if (!authInitialized) {
    setInterval(async () => {
      // Auth.getSession provides the credentials from the local storage.
      const userSession = await Auth.getSession();
      if (shouldRefreshArgoSession(userSession!)) {
        refreshArgoUserSession();
      }
    }, SESSION_REFRESH_PERIOD);
  }

  if (authSession && authInitialized) {
    const getToken = () => { return authSession!.idToken; };
    const jwtToken = getToken();
    if (authSession!.credentials !== null && authSession!.credentials !== undefined) {
      credentialStorage.setUserAttributes(jwtToken, authSession!.credentials.userDirectoryGroupList);
      credentialStorage.setUserCredentials(authSession!.credentials.credentials);
    } else {
      credentialStorage.setUserAttributes(jwtToken);
    }
    props.onApplicationVisitIdChange(uuidv4());
    props.onArgoSessionIdChange(credentialStorage.getAtHash());

    logger = katalLogger(
      Level.INFO,
      {
        appName: props.appName
      },
      false,
      props.katalLoggerUrl
    );

    const context = {
      user: {
        username: credentialStorage.getUserName(),
        signOut: () => {
          authSession = null;
          setAuthInitialized(false);
          sessionStorage.removeItem(BROWSER_SESSION_ID_KEY);
          Auth.signOut();
        },
      },
      frameworkStage: props.frameworkStage,
      frontEndFeatureSet: props.frontEndFeatureSet,
      fetchWithWebIdentity: (input: RequestInfo, init?: RequestInit) => {
        const token = credentialStorage.getJwtToken();
        return fetchWithWebIdentity(input, metricPublisher, token, init);
      },
      fetchWithAwsAuth: (input: RequestInfo, init?: RequestInit) => {
        return fetchWithAWSAuth(input, metricPublisher, authSession, jwtToken, props.region, init);
      },
      custom_groups: credentialStorage.getCustomGroups(),
      argoHadesUrl: props.argoHadesUrl,
      initialMetricsPublisher: metricPublisher,
      logger,
      region: props.region
    };

    context.logger.info('Initial Application StartUp Log', {
      browserName: props.browserName,
      deviceType: props.deviceType,
      deviceOS: props.deviceOS,
      locale: props.locale,
      appName: props.appName,
      cell: props.cell,
      region: props.cognito.region,
      stage: props.frameworkStage,
      applicationVisitId: props.applicationVisitId,
      argoSessionId: props.argoSessionId
    });

    // This metric should not be sent until after credentialStorage.setUserAttributes has been called with the JWT
    sendInternalExternalMetric(metricPublisher, credentialStorage);

    if (legalAgreementAccepted) {
      return (
        <ArgoAppContextProvider value={context}>
          {props.content}
        </ArgoAppContextProvider>
      );
    } else if (siteTermsDocument !== '') {
      return (
        <LegalAgreementCaptivePortal
          onSubmitContinue={() => {
            onAcceptLegalAgreement(credentialStorage.getUserName());
          }}
          siteTermsText={siteTermsDocument}
        />
      );
    } else {
      return (
        <div className='loading'>
          <Box color='text-status-inactive'>
            <Spinner /> Loading
          </Box>
        </div>
      );
    }
  }
  return (
    <div className='loading'>
      <Box color="text-status-inactive">
        <Spinner /> Loading
      </Box>
    </div>
  );
};