const customCSSPropertiesMap: Record<string, string> = {
  breadcrumbsGap: '--awsui-breadcrumbs-gap-zw2xxa',
  contentGapLeft: '--awsui-content-gap-left-zw2xxa',
  contentGapRight: '--awsui-content-gap-right-zw2xxa',
  contentHeight: '--awsui-content-height-zw2xxa',
  containerFirstGap: '--awsui-container-first-gap-zw2xxa',
  containerFirstOverlapExtension: '--awsui-container-first-overlap-extension-zw2xxa',
  defaultMaxContentWidth: '--awsui-default-max-content-width-zw2xxa',
  defaultMinContentWidth: '--awsui-default-min-content-width-zw2xxa',
  drawerSize: '--awsui-drawer-size-zw2xxa',
  footerHeight: '--awsui-footer-height-zw2xxa',
  headerGap: '--awsui-header-gap-zw2xxa',
  headerHeight: '--awsui-header-height-zw2xxa',
  layoutWidth: '--awsui-layout-width-zw2xxa',
  mainGap: '--awsui-main-gap-zw2xxa',
  mainOffsetLeft: '--awsui-main-offset-left-zw2xxa',
  mainTemplateRows: '--awsui-main-template-rows-zw2xxa',
  maxContentWidth: '--awsui-max-content-width-zw2xxa',
  minContentWidth: '--awsui-min-content-width-zw2xxa',
  mobileBarHeight: '--awsui-mobile-bar-height-zw2xxa',
  notificationsGap: '--awsui-notifications-gap-zw2xxa',
  notificationsHeight: '--awsui-notifications-height-zw2xxa',
  offsetTop: '--awsui-offset-top-zw2xxa',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-zw2xxa',
  overlapHeight: '--awsui-overlap-height-zw2xxa',
  navigationWidth: '--awsui-navigation-width-zw2xxa',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-zw2xxa',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-zw2xxa',
  splitPanelHeight: '--awsui-split-panel-height-zw2xxa',
  splitPanelMinWidth: '--awsui-split-panel-min-width-zw2xxa',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-zw2xxa',
  toolsMaxWidth: '--awsui-tools-max-width-zw2xxa',
  toolsWidth: '--awsui-tools-width-zw2xxa',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-zw2xxa',
  contentScrollMargin: '--awsui-content-scroll-margin-zw2xxa',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-zw2xxa',
  flashbarStackIndex: '--awsui-flashbar-stack-index-zw2xxa',
  flashbarStickyBottomMargin: '--awsui-flashbar-sticky-bottom-margin-zw2xxa',
  stackedNotificationsBottomMargin: '--awsui-stacked-notifications-bottom-margin-zw2xxa',
  stackedNotificationsDefaultBottomMargin: '--awsui-stacked-notifications-default-bottom-margin-zw2xxa',
};
export default customCSSPropertiesMap;
